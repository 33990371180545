import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCookie } from "@fortawesome/free-solid-svg-icons"
import cookie from "js-cookie"
import { getPath } from "../utils/gatsby-node-helpers"

const closeText = {
  "en-us": "Accept all",
  "de-de": "Alle akzeptieren",
}

const confirmText = {
  "en-us": "Confirm",
  "de-de": "Meine Auswahl bestätigen",
}

const backText = {
  "en-us": "Back",
  "de-de": "Zurück",
}

const manageCookies = {
  "en-us": "Manage cookies",
  "de-de": "Cookies verwalten",
}

const manageCookiesTitle = {
  "en-us": "Manage cookie consent preferences",
  "de-de": "Cookie-Einstellungen verwalten",
}

const consentText = {
  "en-us": {
    necessary: "Necessary",
    statistics: "Statistics",
    marketing: "Marketing",
  },
  "de-de": {
    necessary: "Notwendig",
    statistics: "Statistik",
    marketing: "Marketing",
  },
}

const setCookie = (name, value) => {
  const isDevelopment = process.env.NODE_ENV === "development"
  const domain = isDevelopment ? "0.0.0.0" : ".moberries.com"
  cookie.set(name, value, { expires: 365, domain })
}

const CookieConsent = ({ locale, handleCookieConsentSubmission }) => {
  const [isConsentVisible, setIsConsentVisible] = useState(false)
  const [isConsentManaging, setIsConsentManaging] = useState(false)
  const [consents, setConsents] = useState({
    statistics: true,
    marketing: true,
  })

  useEffect(() => {
    if (typeof window !== "undefined") {
      const isThereConsent = Boolean(cookie.get("cookie_consent"))
      setIsConsentVisible(!isThereConsent)
    }
  }, [])

  const handleChangeConsent = e => {
    const { value, checked } = e.target
    setConsents(state => ({ ...state, [value]: checked }))
  }

  const handleCloseConsent = () => {
    setIsConsentVisible(false)
    setCookie("cookie_consent", JSON.stringify(consents))
    handleCookieConsentSubmission(consents)
  }

  const handleManageConsent = () => {
    if (isConsentManaging) {
      setConsents({
        statistics: true,
        marketing: true,
      })
    }
    setIsConsentManaging(!isConsentManaging)
  }

  if (!isConsentVisible) {
    return null
  }

  return (
    <div className="cookie-consent">
      <div className="d-flex align-items-center border-bottom pb-3 mb-3">
        <FontAwesomeIcon
          icon={faCookie}
          color="#eb9161"
          className="mr-3 d-none d-sm-block"
          size="6x"
        />
        {isConsentManaging ? (
          <div>
            <div className="mb-4 text-center">{manageCookiesTitle[locale]}</div>
            <div>
              <div className="d-flex align-items-md-center flex-column flex-md-row">
                <div className="d-flex flex-wrap mb-3 mb-md-0">
                  <div className="custom-control custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="cookie-consent-necessary"
                      type="checkbox"
                      value="necessary"
                      disabled
                      checked
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="cookie-consent-necessary"
                    >
                      {consentText[locale].necessary}
                    </label>
                  </div>

                  <div className="custom-control custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="cookie-consent-statistics"
                      type="checkbox"
                      value="statistics"
                      checked={consents.statistics}
                      onChange={handleChangeConsent}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="cookie-consent-statistics"
                    >
                      {consentText[locale].statistics}
                    </label>
                  </div>

                  <div className="custom-control custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="cookie-consent-marketing"
                      type="checkbox"
                      value="marketing"
                      checked={consents.marketing}
                      onChange={handleChangeConsent}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="cookie-consent-marketing"
                    >
                      {consentText[locale].marketing}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : locale === "en-us" ? (
          <div>
            <p>
              We use cookies to personalize content, ads, and to analyze our
              traffic. You can read more about the used cookies and services in
              our{" "}
              <Link
                to={getPath({
                  docType: "title-paragraphs",
                  uid: "privacy",
                  lang: locale,
                })}
              >
                privacy policy
              </Link>
              .
            </p>
          </div>
        ) : (
          <div>
            <p>
              Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren
              und unsere Statistiken zu analysieren. Sie können mehr über die
              verwendeten Cookies und Dienste in unserer{" "}
              <Link
                to={getPath({
                  docType: "title-paragraphs",
                  uid: "privacy",
                  lang: locale,
                })}
              >
                Datenschutzrichtlinie
              </Link>{" "}
              lesen.
            </p>
            <p className="mb-0">
              Sie stimmen dem Verwenden von Cookies zu, wenn Sie unsere Website
              weiterhin nutzen.
            </p>
          </div>
        )}
      </div>

      <div className="d-flex align-items-md-center flex-column flex-md-row">
        <button
          type="button"
          className="btn ml-auto"
          onClick={handleManageConsent}
        >
          <u>{isConsentManaging ? backText[locale] : manageCookies[locale]}</u>
        </button>
        <button
          type="button"
          className="btn btn-primary ml-2"
          onClick={handleCloseConsent}
        >
          {isConsentManaging ? confirmText[locale] : closeText[locale]}
        </button>
      </div>
    </div>
  )
}

export default CookieConsent
