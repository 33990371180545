import React from "react"
import { Link } from "./link-component"
import FooterLogo from "../assets/mb-logo-footer.svg"
import { footerLinks, socialLinks } from "../utils/links"
import cn from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons"

const iconMap = {
  facebook: faFacebookF,
  instagram: faInstagram,
  twitter: faTwitter,
  linkedin: faLinkedin,
  youtube: faYoutube,
}

const Footer = ({ mainPage, locale }) => (
  <footer className="main pt-7">
    <div className="container">
      <div className="row">
        <div className="col-md">
          <div className="row">
            <div className="col-6">
              <ul className="list-unstyled m-0">
                {footerLinks[locale].menuItems.map((link, i) => (
                  <li key={i}>
                    <Link
                      isInternal={link.isInternal}
                      to={link.to}
                      label={link.label}
                      className="d-block py-1"
                    />
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-6">
              <ul className="list-unstyled m-0">
                {footerLinks[locale][mainPage].legalLinks.map((link, i) => (
                  <li key={i}>
                    <Link
                      isInternal={link.isInternal}
                      to={link.to}
                      label={link.label}
                      className="d-block py-1"
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md">
          <div className="row">
            <div className="col-6">
              <ul className="list-unstyled">
                {footerLinks[locale][mainPage].supportLinks.map((link, i) => (
                  <li key={i}>
                    <Link
                      isInternal={link.isInternal}
                      to={link.to}
                      label={link.label}
                      className="d-block py-1"
                    />
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-6 text-md-right d-none d-md-block">
              <img
                className="lazy loaded"
                width="104"
                src={FooterLogo}
                alt="footer Logo"
              />
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="row pb-3 text-center">
        <div className="col-md text-md-left mb-3 mb-md-0">
          MoBerries © {new Date().getFullYear()}
          {/* <span className="text-muted"> | support@moberries.com</span> */}
        </div>
        <div className="col-md text-md-right">
          {socialLinks.map((link, i) => (
            <a
              key={i}
              className={cn("d-inline-block ml-md-1", {
                "pl-2": i === socialLinks.length - 1,
                "px-2": i !== socialLinks.length - 1,
              })}
              href={link.to}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={iconMap[link.label]}
                className="ml-1"
                size="lg"
              />
            </a>
          ))}
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
