import React from "react"
import markImage from "../assets/checkmark.svg"
import { Link } from "./link-component"

export const AuthChoiceButton = ({ title, imageUrl, url, points = [] }) => {
  return (
    <div
      className="rounded text-center m-2 w-100"
      style={{ backgroundColor: "#eaf4fc" }}
    >
      <Link to={url} className="text-decoration-none text-secondary w-100">
        <div className="w-100  p-3">
          <h5>{title}</h5>
          <img src={imageUrl} alt={title} style={{ minWidth: "100px" }} />
          <div className="text-left mt-2">
            {points.map(point => {
              return (
                <div key={point} className="d-flex">
                  <img
                    src={markImage}
                    className="mr-2"
                    alt="V"
                    width={24}
                    height={24}
                  />
                  <p style={{ fontSize: "14px" }}>{point}</p>
                </div>
              )
            })}
          </div>
        </div>
      </Link>
    </div>
  )
}
