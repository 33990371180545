import ogImage from "../assets/images/og-candidates.png"
const BRANCH = process.env.GATSBY_BRANCH

let moberriesApiUrl = "https://api.dev.moberries.com/api/v2/"
let proxyApiUrl = "https://proxy.dev.moberries.com"
let moberriesWebsiteUrl = "https://www.dev.moberries.com"
let moberriesCandidateAppUrl = "https://app.dev.moberries.com"

if (BRANCH === "release") {
  moberriesApiUrl = "https://api.moberries.com/api/v2/"
  proxyApiUrl = "https://proxy.moberries.com"
  moberriesWebsiteUrl = "https://www.moberries.com"
  moberriesCandidateAppUrl = "https://app.moberries.com"
}

if (BRANCH === "staging") {
  moberriesApiUrl = "https://api.staging.moberries.com/api/v2/"
  proxyApiUrl = "https://proxy.staging.moberries.com"
  moberriesWebsiteUrl = "https://www.staging.moberries.com"
  moberriesCandidateAppUrl = "https://app.staging.moberries.com"
}

if (BRANCH === "demo1") {
  moberriesApiUrl = "https://api.demo1.moberries.com/api/v2/"
  proxyApiUrl = "https://proxy.demo1.moberries.com"
  moberriesWebsiteUrl = "https://www.demo1.moberries.com"
  moberriesCandidateAppUrl = "https://app.demo1.moberries.com"
}

export const config = {
  moberries: {
    api: {
      url: moberriesApiUrl,
      proxy: proxyApiUrl,
    },
    baseUrl: moberriesWebsiteUrl,
    candidateAppUrl: moberriesCandidateAppUrl,
  },
  cookie: {
    utmSource: "utm_source",
    utmMedium: "utm_medium",
    utmCampaign: "utm_campaign",
    utmTerm: "utm_term",
    utmContent: "utm_content",
  },
  google: {
    recaptcha: {
      key: "6LezVpsfAAAAADRupErqRt6AfOjMmYVnxw1nmLmp",
    },
  },
}

export const defaultMetatags = {
  title: "MoBerries - Matching Best Talent With Great Companies.",
  description:
    "Join MoBerries - the largest network connecting talent to companies via matching algorithm. On-demand recruitment for employers, easy applying for job seekers.",
  keywords:
    "job platform, hiring platform, on-demand recruitment, talent pool, candidate source, job matching, job application, job offers, jobs, job search",
  image: ogImage,
  type: "website",
}
