import React, { useState } from "react"
import cn from "classnames"
import { Link } from "./link-component"
import { isPartiallyActive } from "../utils/helpers"

const NavbarDropdown = ({ link }) => {
  const [active, setActive] = useState(false)

  return (
    <li className="nav-item dropdown">
      <p
        className="nav-link dropdown-toggle text-white cursor-pointer"
        role="button"
        onClick={() => setActive(!active)}
      >
        {link.link_title}
      </p>
      <div className={cn("dropdown-menu bg-dark p-0", { show: active })}>
        {link.dropdown.map((dropdown, i) => (
          <Link
            key={i}
            isInternal={dropdown.link_url.link_type === "Document"}
            to={dropdown.link_url.url}
            label={dropdown.link_title}
            className="dropdown-item"
            getProps={
              dropdown.link_url.url === "/" || dropdown.link_url.url === "/de/"
                ? undefined
                : isPartiallyActive
            }
          />
        ))}
      </div>
    </li>
  )
}

export default NavbarDropdown
