import React, { Fragment, useState, useEffect } from "react"
import { Link } from "./link-component"
import { Link as GatsbyLink, StaticQuery, graphql } from "gatsby"
import Logo from "../assets/mb-logo.svg"
import { localizePath, switchLanguage } from "../utils/helpers"
import cn from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"
import NavbarDropdown from "./navbar-dropdown"
import NavbarLink from "./navbar-link"
import NavbarButton from "./navbar-button"
import { AuthChoiceModal } from "./auth-choice-modal"

const transformToNavLinks = (links, dropdowns) => {
  const navLinks = []
  dropdowns = dropdowns.reduce((acc, curr) => {
    acc[curr.prismicId] = curr.data
    return acc
  }, {})

  links.forEach(link => {
    const navLink = {
      link_url: link.navbar_link,
      type: "link",
      link_title: link.navbar_link_title,
    }
    if (link.navbar_dropdown?.id) {
      navLink.dropdown = dropdowns[link.navbar_dropdown.id].dropdown_links
      navLink.type = "dropdown"
    }
    navLinks.push(navLink)
  })

  return navLinks
}

const isHeaderButton = link => {
  return link.endsWith("/login") || link.endsWith("/signup")
}

const Header = ({
  locale,
  path,
  alternateLanguages,
  navbar = [],
  navbarDropdown = [],
  isLandingPage,
  loginSignupModalData,
}) => {
  const navLinks = transformToNavLinks(navbar, navbarDropdown)
  const [showNavbar, setShowNavbar] = useState(false)
  const [isOff, setIsOff] = useState(false)
  const [openedModal, setOpenedModal] = useState(null)
  const handleSwitchClick = () => {
    if (
      path === "/de" ||
      path === "/de/" ||
      path === "/de/candidates" ||
      path === "/de/candidates/"
    ) {
      typeof window !== "undefined" &&
        window.localStorage.setItem("hasSwitchedLanguage", true)
    }
  }
  const handleScroll = () => {
    const headerEl = document.getElementById("main-header")
    const headerHeight = headerEl && headerEl.clientHeight
    const distanceY = typeof window !== "undefined" && window.pageYOffset
    if (distanceY > headerHeight) {
      setIsOff(true)
    } else {
      setIsOff(false)
    }
  }

  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.addEventListener("scroll", handleScroll)
    }
    return () => {
      if (typeof window !== `undefined`) {
        window.removeEventListener("scroll", handleScroll)
      }
    }
  }, [])

  return (
    <Fragment>
      <AuthChoiceModal
        data={loginSignupModalData}
        locale={locale}
        openedModal={openedModal}
        setOpenedModal={setOpenedModal}
      />
      <header
        className={cn("sticky-top bg-dark header", {
          off: isOff && !isLandingPage,
          "header-animated": !isLandingPage,
        })}
        id="main-header"
      >
        <div className="container">
          <div className="d-flex">
            <GatsbyLink to={localizePath("/", locale)}>
              <img src={Logo} alt="Moberries" width="185" height="40" />
            </GatsbyLink>

            <a
              className="mobile-toggle d-inline-block d-lg-none ml-auto"
              onClick={() => setShowNavbar(!showNavbar)}
              href="#mobileNav"
              role="button"
              aria-expanded="true"
              aria-controls="mobileNav"
            >
              <FontAwesomeIcon icon={faBars} />
            </a>
            <ul className="nav ml-auto d-none d-lg-flex">
              {navLinks.map(link => {
                return link.type === "dropdown" ? (
                  <NavbarDropdown link={link} key={link.link_title} />
                ) : isHeaderButton(link.link_url.url) ? (
                  <NavbarButton
                    link={link}
                    key={link.link_title}
                    onClick={() => setOpenedModal(link.link_url.url)}
                  />
                ) : (
                  <NavbarLink link={link} key={link.link_title} />
                )
              })}
              <li className="nav-item" onClick={handleSwitchClick}>
                <Link
                  isInternal
                  to={switchLanguage(path, locale, alternateLanguages)}
                  label={locale === "en-us" ? "DE" : "EN"}
                  className="nav-link"
                  activeClassName="nav-link nav-link-active"
                />
              </li>
            </ul>
          </div>
        </div>
      </header>
      <div
        className={cn("position-fixed w-100 bg-dark py-3 collapse", {
          show: showNavbar,
        })}
        id="mobileNav"
      >
        <div className="container">
          <ul className="nav flex-column">
            {navLinks.map(link => {
              return link.type === "dropdown" ? (
                <NavbarDropdown link={link} key={link.link_title} />
              ) : isHeaderButton(link.link_url.url) ? (
                <NavbarButton
                  link={link}
                  key={link.link_title}
                  onClick={() => setOpenedModal(link.link_url.url)}
                />
              ) : (
                <NavbarLink link={link} key={link.link_title} />
              )
            })}

            <li className="nav-item" onClick={handleSwitchClick}>
              <Link
                isInternal
                to={switchLanguage(path, locale, alternateLanguages)}
                label={locale === "en-us" ? "DE" : "EN"}
                className="nav-link"
                activeClassName="nav-link nav-link-active"
              />
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query Navbar {
        allPrismicNavbar {
          nodes {
            lang
            data {
              candidates {
                navbar_link_title
                navbar_link {
                  url
                  type
                  target
                  link_type
                }
                navbar_dropdown {
                  id
                  link_type
                }
              }
              companies {
                navbar_link_title
                navbar_link {
                  url
                  type
                  target
                  link_type
                }
                navbar_dropdown {
                  id
                  link_type
                }
              }
              recruiters {
                navbar_link_title
                navbar_link {
                  url
                  type
                  target
                  link_type
                }
                navbar_dropdown {
                  id
                  link_type
                }
              }
              default {
                navbar_link_title
                navbar_link {
                  url
                  type
                  target
                  link_type
                }
                navbar_dropdown {
                  id
                  link_type
                }
              }
            }
          }
        }
        allPrismicNavbarDropdown {
          nodes {
            data {
              dropdown_links {
                link_title
                link_url {
                  url
                  type
                  target
                }
              }
            }
            prismicId
          }
        }
        allPrismicLogin {
          nodes {
            lang
            data {
              main_title_login
              main_title_signup
              companies_title
              candidates_title
              recruiters_title
              candidates_illustration {
                url
              }
              companies_illustration {
                url
              }
              recruiters_illustration {
                url
              }
              candidates_key_points {
                key_point_text
              }
              companies_key_points {
                key_point_text
              }
              recruiters_key_points {
                key_point_text
              }
              candidates_login {
                url
              }
              candidates_signup {
                url
              }
              companies_login {
                url
              }
              companies_signup {
                url
              }
              recruiters_login {
                url
              }
              recruiters_signup {
                url
              }
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <Header
          navbar={
            data.allPrismicNavbar.nodes.filter(
              item => item.lang === props.locale,
            )[0].data[props.mainPage]
          }
          navbarDropdown={data.allPrismicNavbarDropdown?.nodes}
          langSwitchBtnOrdr={
            data.prismicNavbar?.data.order_of_the_language_switch_button
          }
          loginSignupModalData={data.allPrismicLogin?.nodes}
          {...props}
        />
      )
    }}
  />
)
