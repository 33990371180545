import React from "react"

const NavbarButton = ({ onClick, link }) => {
  return (
    <li className="nav-item">
      <button
        className="border-0 bg-transparent text-white m-0 nav-link"
        style={{ outline: "none" }}
        onClick={onClick}
      >
        {link.link_title}
      </button>
    </li>
  )
}

export default NavbarButton
