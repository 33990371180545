import React from "react"
import { Link as GatsbyLink } from "gatsby"

export const Link = ({
  isInternal,
  to,
  label,
  className,
  activeClassName,
  getProps,
  children,
  style,
}) => {
  if (isInternal) {
    return (
      <GatsbyLink
        activeClassName={activeClassName}
        className={className}
        to={to}
        getProps={getProps}
        style={style}
      >
        {label || children}
      </GatsbyLink>
    )
  }
  return (
    <a className={className} style={style} href={to}>
      {label || children}
    </a>
  )
}
