import React from "react"
import { Link } from "./link-component"
import { footerLinks } from "../utils/links"

const Footer = ({ mainPage, locale }) => (
  <footer className="main pt-3">
    <div className="container">
      <div className="row pb-3 text-center">
        <div className="col-md-auto text-md-left mb-3 mb-md-0">
          MoBerries © {new Date().getFullYear()}
          {/* <span className="text-muted"> | support@moberries.com</span> */}
        </div>
        <div className="col-md text-md-right">
          {footerLinks[locale][mainPage].legalLinks
            .filter((_, i) => i > 0)
            .map((link, i) => (
              <Link
                key={i}
                isInternal={link.isInternal}
                to={link.to}
                label={link.label}
                className="mr-3"
              />
            ))}
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
