import React, { Fragment, useEffect } from "react"
import { Helmet } from "react-helmet"
import { ReactNotifications } from "react-notifications-component"
import "react-notifications-component/dist/theme.css"
import "../styles/main.scss"
import Header from "./header"
import HeaderReduced from "./header-reduced"
import Footer from "./footer"
import CookieConsent from "./cookie-consent"
import FooterReduced from "./footer-reduced"
import {
  getMetatag,
  localizeMetaTags,
  switchLanguage,
  loadGtm,
  addTrailingSlash,
} from "../utils/helpers"
import { StaticQuery, graphql } from "gatsby"
import { config } from "../utils/config"
import sal from "sal.js"

const Layout = ({
  children,
  metadata,
  mainPage,
  locale = "en-us",
  path,
  defaultMetaTags,
  alternateLanguages,
  reducedLayout,
  isLandingPage,
}) => {
  const onCookieConsentSubmit = consents => {
    if (consents.statistics) {
      setTimeout(loadGtm, 1500)
      // TODO: load other statistics scripts
    }
    if (consents.marketing) {
      // TODO: load marketing scripts
    }
  }
  useEffect(() => {
    sal()
    if (typeof window !== "undefined") {
      require("browser-update")({
        required: {
          c: -5,
          e: -5,
          f: -5,
          o: -5,
          v: -5,
          y: -5,
          i: 0,
          s: -1,
          samsung: -3,
        },
        insecure: true,
        no_permanent_hide: true,
        reminder: 14 * 24,
      })
    }
  }, [])
  const localizedDefaultMetaTags =
    localizeMetaTags(defaultMetaTags, locale) || {}
  const alternatePathEn = `${config.moberries.baseUrl}${
    locale === "en-us"
      ? path
      : switchLanguage(path, "de-de", alternateLanguages)
  }`
  const alternatePathDe = `${config.moberries.baseUrl}${
    locale === "de-de"
      ? path
      : switchLanguage(path, "en-us", alternateLanguages)
  }`

  const title = getMetatag("title", metadata, localizedDefaultMetaTags)
  const description = getMetatag(
    "description",
    metadata,
    localizedDefaultMetaTags,
  )
  const image = getMetatag("image", metadata, localizedDefaultMetaTags)
  const canonical = addTrailingSlash(`${config.moberries.baseUrl}${path}`)
  const keywords = getMetatag("keywords", metadata, localizedDefaultMetaTags)
  const type = "website"

  return (
    <Fragment>
      <Helmet>
        <html lang={locale} />
        <link rel="canonical" href={canonical} />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta
          property="og:description"
          content={description}
          key="og:description"
        />
        <meta name="keywords" content={keywords} />
        <meta property="fb:app_id" content="502997536545967" />
        <meta property="og:keywords" content={keywords} />
        <meta property="og:title" content={title} />
        <meta property="og:type" content={type} />
        <meta name="type" content={type} />
        <meta property="og:image" content={image} />
        <meta name="image" content={image} />
        <meta property="og:url" content={canonical} />

        <meta
          name="google-site-verification"
          content="nOLbRHWYJF5er-PrH216-TQTBmn93sb86pms178Rn2g"
        />

        <link rel="alternate" href={alternatePathEn} hrefLang="en" />
        <link rel="alternate" href={alternatePathDe} hrefLang="de-de" />
      </Helmet>
      <ReactNotifications />
      {reducedLayout ? (
        <HeaderReduced
          locale={locale}
          path={path}
          alternateLanguages={alternateLanguages}
        />
      ) : (
        <Header
          mainPage={mainPage}
          locale={locale}
          path={path}
          alternateLanguages={alternateLanguages}
          isLandingPage={isLandingPage}
        />
      )}
      <div className="content">{children}</div>
      {reducedLayout ? (
        <FooterReduced mainPage={mainPage} locale={locale} />
      ) : (
        <Footer mainPage={mainPage} locale={locale} />
      )}
      <CookieConsent
        locale={locale}
        handleCookieConsentSubmission={onCookieConsentSubmit}
      />
    </Fragment>
  )
}
export default props => (
  <StaticQuery
    query={graphql`
      query DefaultMetaTags {
        allPrismicDefaultMetaTags {
          nodes {
            data {
              description
              image {
                url
              }
              keywords
              title
              type
            }
            lang
          }
        }
      }
    `}
    render={data => (
      <Layout
        defaultMetaTags={data.allPrismicDefaultMetaTags.nodes}
        {...props}
      />
    )}
  />
)
