import React from "react"
import { Link } from "./link-component"
import { isPartiallyActive } from "../utils/helpers"

const NavbarLink = ({ link }) => {
  return (
    <li className="nav-item">
      <Link
        isInternal={link.link_url.link_type === "Document"}
        to={link.link_url.url}
        label={link.link_title}
        className="nav-link"
        activeClassName="nav-link nav-link-active"
        getProps={
          link.link_url.url === "/" || link.link_url.url === "/de/"
            ? undefined
            : isPartiallyActive
        }
      />
    </li>
  )
}

export default NavbarLink
