import React from "react"
import { AuthChoiceButton } from "./auth-choice-button"
import { Modal } from "./modal"

const headerLinkToModalMapper = link => {
  if (typeof link !== "string") return null
  switch (true) {
    case link.includes("/login"):
      return "login"
    case link.includes("/signup"):
      return "signup"
    default:
      return null
  }
}

export const AuthChoiceModal = ({
  data,
  locale,
  openedModal,
  setOpenedModal,
}) => {
  const modalData = data.find(i => i.lang === locale).data

  const currentModalName = headerLinkToModalMapper(openedModal)
  const choices = ["candidates", "companies", "recruiters"]
  return (
    <Modal
      onClose={() => setOpenedModal(null)}
      isOpen={!!openedModal}
      title={modalData[`main_title_${currentModalName}`]}
    >
      <div className="d-flex justify-content-around">
        {currentModalName &&
          choices.map(choice => (
            <AuthChoiceButton
              key={choice}
              url={modalData[`${choice}_${currentModalName}`].url}
              title={modalData[`${choice}_title`]}
              imageUrl={modalData[`${choice}_illustration`].url}
              points={
                currentModalName === "signup"
                  ? modalData[`${choice}_key_points`].map(i => i.key_point_text)
                  : []
              }
            />
          ))}
      </div>
    </Modal>
  )
}
