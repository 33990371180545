import React from "react"
import { useOutsideEvent } from "../hooks/use-outside-event"

export const Modal = props => {
  // close modal if click event happened outside of modal
  const ref = useOutsideEvent(props.onClose)
  return props.isOpen ? (
    <div
      className="position-fixed d-flex justify-content-center align-items-center"
      style={{
        zIndex: 2001,
        width: "100%",
        height: "100vh",
        background: "rgba(0,0,0, 0.5)",
      }}
    >
      <div
        className="bg-white rounded p-4"
        style={{ width: "900px" }}
        ref={ref}
      >
        <div className="d-flex justify-content-between align-items-center mb-4">
          <div className="w-100 text-center">
            <h5 className="text-secondary m-0">{props.title}</h5>
          </div>
          <button
            className="border-0 text-black-50 bg-transparent"
            onClick={props.onClose}
          >
            ✕
          </button>
        </div>
        <div>{props.children}</div>
      </div>
    </div>
  ) : null
}
