import head from "lodash/head"
import isNil from "lodash/isNil"
import { RichText } from "prismic-reactjs"
import { config } from "./config"
import cookie from "js-cookie"
import Hashids from "hashids"
import { Store } from "react-notifications-component"
import browserLang from "browser-lang"
import { getPath } from "./gatsby-node-helpers"

export const localizePath = (path, locale) =>
  locale === "de-de" ? `/de${path}` : path

// used to determine which links to show on the header and footer
export function getMainPage(uid) {
  if (uid.includes("recruiters")) {
    return "recruiters"
  }
  if (uid.includes("companies")) {
    return "companies"
  }
  return "default"
}

export const switchLanguage = (path, locale, alternateLanguages) => {
  if (alternateLanguages?.length > 0) {
    const alternateDocument = head(alternateLanguages)
    const path = getPath({
      docType: alternateDocument.type,
      uid: alternateDocument.uid,
      lang: alternateDocument.lang,
    })
    return path
  }
  return locale === "de-de" ? path.replace("/de", "") : `/de${path}`
}

export const isEven = n => n % 2 === 0

export const getMetatag = (metaTagName, metadata, defaultMetatags) => {
  if (metaTagName === "image") {
    return metadata && metadata[metaTagName]
      ? metadata[metaTagName]
      : defaultMetatags[metaTagName]?.url
  }
  return metadata && metadata[metaTagName]
    ? metadata[metaTagName]
    : defaultMetatags[metaTagName]
}

export const isPartiallyActive = ({ isPartiallyCurrent }) => {
  return isPartiallyCurrent ? { className: "nav-link nav-link-active" } : null
}

export const localizeMetaTags = (defaultMetaTags, locale) =>
  head(defaultMetaTags.filter(m => m.lang === locale))?.data

export const getTextFromSlices = slices =>
  slices
    .filter(s => s.slice_type === "content_blog")
    .map(i => RichText.asText(i.primary.content_blog.raw))
    .join(" ")

export function isEmailValid(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export function getUtmParams() {
  const paramNames = [
    config.cookie.utmSource,
    config.cookie.utmMedium,
    config.cookie.utmCampaign,
    config.cookie.utmTerm,
    config.cookie.utmContent,
  ]

  const params = paramNames.reduce((params, name) => {
    const param = cookie.get(name)
    if (param) {
      params[name] = param
    }

    return params
  }, {})

  if (Object.keys(params).length < 1) {
    return null
  }

  return params
}

export const validateFullName = input => {
  if (!input) return false
  const fullName = input.split(" ")
  if (fullName.length <= 1) {
    return false
  } else if (fullName.length > 2) {
    return false
  } else {
    if (/[^a-zA-Z\s-]+/.test(fullName[0])) {
      return false
    }
    if (/[^a-zA-Z\s-]+/.test(fullName[1])) {
      return false
    }
  }
  return true
}

export function getReferralUserId() {
  const code = getParameterByName("ref")
  if (!code) {
    return null
  }
  const [userId] = new Hashids().decode(code)
  return isNil(userId) ? null : userId
}

function getParameterByName(name) {
  const url = typeof window !== "undefined" && window.location.href
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[\]]/g, "\\$&")
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)")
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ""
  return decodeURIComponent(results[2].replace(/\+/g, " "))
}

export function redirectIfNeeded({ locale, location }) {
  // https://github.com/moberries/website/issues/27
  if (typeof window !== "undefined" && locale === "en-us") {
    const hasSwitchedLanguage =
      window.localStorage.getItem("hasSwitchedLanguage") === "true"
    if (!hasSwitchedLanguage) {
      const browserLanguage = browserLang({
        languages: ["en", "de"],
        fallback: "en",
      })
      const isWrongLanguage = browserLanguage.substring(0, 2) === "de"

      if (isWrongLanguage) {
        const nextPath = localizePath(location.pathname, "de-de")
        window.location.href = nextPath + location.hash
      }
    }
  }
}

export function loadGtm() {
  ;(function (w, d, s, l, i) {
    w[l] = w[l] || []
    w[l].push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    })
    const f = d.getElementsByTagName(s)[0], // eslint-disable-line
      j = d.createElement(s),
      dl = l !== "dataLayer" ? `&l=${l}` : ""
    j.async = true
    j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`
    f.parentNode.insertBefore(j, f)
  })(window, document, "script", "dataLayer", "GTM-MD64MXL")
}

export const addTrailingSlash = path =>
  path[path.length - 1] === "/" ? path : `${path}/`

export const redirect = path => {
  window.location.href = path
}

export const showNotification = ({ message, type, title }) => {
  Store.addNotification({
    title,
    message,
    type: type || "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
  })
}
