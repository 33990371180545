import React, { Fragment } from "react"
import { Link } from "./link-component"
import { Link as GatsbyLink } from "gatsby"
import Logo from "../assets/mb-logo.svg"
import { localizePath, switchLanguage } from "../utils/helpers"

const Header = ({ locale, path, alternateLanguages }) => {
  const handleSwitchClick = () => {
    if (
      path === "/de" ||
      path === "/de/" ||
      path === "/de/companies" ||
      path === "/de/companies/"
    ) {
      typeof window !== "undefined" &&
        window.localStorage.setItem("hasSwitchedLanguage", true)
    }
  }
  return (
    <Fragment>
      <header className="sticky-top bg-dark header">
        <div className="container">
          <div className="d-flex justify-content-between">
            <GatsbyLink to={localizePath("/", locale)}>
              <img src={Logo} alt="Moberries" width="185" height="40" />
            </GatsbyLink>

            <Link
              onClick={handleSwitchClick}
              isInternal
              to={switchLanguage(path, locale, alternateLanguages)}
              label={locale === "en-us" ? "DE" : "EN"}
              className="btn btn-link text-light"
              activeClassName="nav-link nav-link-active"
            />
          </div>
        </div>
      </header>
    </Fragment>
  )
}

export default Header
