export const headerLinks = {
  "en-us": {
    candidates: [
      { to: "/", label: "For Employers", isInternal: true },
      { to: "/candidates", label: "For Candidates", isInternal: true },
      {
        to: `/blog`,
        label: "Blog",
        isInternal: true,
      },
      {
        to: `https://app.moberries.com/login`,
        label: "Log in",
        isInternal: false,
      },
      {
        to: `https://app.moberries.com/signup`,
        label: "Sign up",
        isInternal: false,
      },
    ],
    companies: [
      { to: "/", label: "For Employers", isInternal: true },
      { to: "/candidates", label: "For Candidates", isInternal: true },
      {
        to: `/blog`,
        label: "Blog",
        isInternal: true,
      },
      {
        to: `https://my.moberries.com/login`,
        label: "Log in",
        isInternal: false,
      },
      {
        to: `https://my.moberries.com/signup`,
        label: "Sign up",
        isInternal: false,
      },
    ],
    recruiters: [
      { to: "/", label: "For Employers", isInternal: true },
      { to: "/candidates", label: "For Candidates", isInternal: true },
      {
        to: `/blog`,
        label: "Blog",
        isInternal: true,
      },
      {
        to: `https://recruiter.moberries.com/login`,
        label: "Log in",
        isInternal: false,
      },
      {
        to: `https://recruiter.moberries.com/signup`,
        label: "Sign up",
        isInternal: false,
      },
    ],
    default: [
      { to: "/", label: "For Employers", isInternal: true },
      { to: "/candidates", label: "For Candidates", isInternal: true },
      {
        to: `/blog`,
        label: "Blog",
        isInternal: true,
      },
      {
        to: `https://recruiter.moberries.com/login`,
        label: "Log in",
        isInternal: false,
      },
      {
        to: `https://recruiter.moberries.com/signup`,
        label: "Sign up",
        isInternal: false,
      },
    ],
  },
  "de-de": {
    candidates: [
      { to: "/de/", label: "Für Unternehmen", isInternal: true },
      { to: "/de/candidates", label: "Für Kandidaten", isInternal: true },
      {
        to: `/de/blog`,
        label: "Blog",
        isInternal: true,
      },
      {
        to: `https://app.moberries.com/login`,
        label: "Log in",
        isInternal: false,
      },
      {
        to: `https://app.moberries.com/signup`,
        label: "Sign up",
        isInternal: false,
      },
    ],
    companies: [
      { to: "/de/", label: "Für Unternehmen", isInternal: true },
      { to: "/de/candidates", label: "Für Kandidaten", isInternal: true },
      {
        to: `/de/blog`,
        label: "Blog",
        isInternal: true,
      },
      {
        to: `https://my.moberries.com/login`,
        label: "Log in",
        isInternal: false,
      },
      {
        to: `https://my.moberries.com/signup`,
        label: "Sign up",
        isInternal: false,
      },
    ],
    recruiters: [
      { to: "/de/", label: "Für Unternehmen", isInternal: true },
      { to: "/de/candidates", label: "Für Kandidaten", isInternal: true },
      {
        to: `/de/blog`,
        label: "Blog",
        isInternal: true,
      },
      {
        to: `https://recruiter.moberries.com/login`,
        label: "Log in",
        isInternal: false,
      },
      {
        to: `https://recruiter.moberries.com/signup`,
        label: "Sign up",
        isInternal: false,
      },
    ],
    default: [
      { to: "/de/", label: "Für Unternehmen", isInternal: true },
      { to: "/de/candidates", label: "Für Kandidaten", isInternal: true },
      {
        to: `/de/blog`,
        label: "Blog",
        isInternal: true,
      },
      {
        to: `https://recruiter.moberries.com/login`,
        label: "Log in",
        isInternal: false,
      },
      {
        to: `https://recruiter.moberries.com/signup`,
        label: "Sign up",
        isInternal: false,
      },
    ],
  },
}

export const footerLinks = {
  "en-us": {
    menuItems: [
      {
        to: "https://www.moberries.com/jobs",
        label: "Jobs",
        isInternal: false,
      },
      { to: "/page/about-moberries", label: "About", isInternal: true },
      {
        to: `/blog`,
        label: "Blog",
        isInternal: true,
      },
      {
        to: `https://www.moberries.com/jobs/company/moberries`,
        label: "Careers",
        isInternal: false,
      },
      // MOB-974
      // {
      //   to: "/page/partner-api-doc",
      //   label: "API docs for partners",
      //   isInternal: true,
      // },
    ],
    candidates: {
      legalLinks: [
        { to: "/page/press", label: "Press", isInternal: true },
        {
          to: "/legal",
          label: "Legal Notice",
          isInternal: true,
        },
        { to: "/privacy", label: "Data Protection", isInternal: true },
        {
          to: `/terms`,
          label: "Terms & Conditions",
          isInternal: true,
        },
      ],

      supportLinks: [
        {
          to:
            "https://help.moberries.com/en/collections/2140782-for-candidates",
          label: "FAQ",
          isInternal: false,
        },
        {
          to: "https://www.moberries.com/page/candidateguide",
          label: "Guide",
          isInternal: false,
        },
        {
          to: `mailto:support@moberries.com`,
          label: "Support",
          isInternal: false,
        },
        {
          to: `/sitemap`,
          label: "Sitemap",
          isInternal: true,
        },
      ],
    },

    companies: {
      legalLinks: [
        { to: "/page/press", label: "Press", isInternal: true },
        {
          to: "/legal",
          label: "Legal Notice",
          isInternal: true,
        },
        {
          to: "/companies/privacy",
          label: "Data Protection Agreement",
          isInternal: true,
        },
        {
          to: "/privacy",
          label: "Privacy Policy",
          isInternal: true,
        },
        {
          to: `/companies/terms`,
          label: "Partner Terms",
          isInternal: true,
        },
      ],

      supportLinks: [
        {
          to: "https://help.moberries.com/en/collections/2099568-for-companies",
          label: "FAQ",
          isInternal: false,
        },
        {
          to: "https://www.moberries.com/page/company-guide",
          label: "Guide",
          isInternal: false,
        },
        {
          to: `mailto:support@moberries.com`,
          label: "Support",
          isInternal: false,
        },
        {
          to: `/sitemap`,
          label: "Sitemap",
          isInternal: true,
        },
      ],
    },
    default: {
      legalLinks: [
        { to: "/page/press", label: "Press", isInternal: true },
        {
          to: "/legal",
          label: "Legal Notice",
          isInternal: true,
        },
        {
          to: "/companies/privacy",
          label: "Data Protection Agreement",
          isInternal: true,
        },
        {
          to: `/companies/terms`,
          label: "Partner Terms",
          isInternal: true,
        },
      ],

      supportLinks: [
        {
          to: "https://help.moberries.com/en/collections/2099568-for-companies",
          label: "FAQ",
          isInternal: false,
        },
        {
          to: "https://www.moberries.com/page/company-guide",
          label: "How-To guide",
          isInternal: false,
        },
        {
          to: `mailto:support@moberries.com`,
          label: "Support",
          isInternal: false,
        },
        {
          to: `/sitemap`,
          label: "Sitemap",
          isInternal: true,
        },
      ],
    },
    recruiters: {
      legalLinks: [
        { to: "/page/press", label: "Press", isInternal: true },
        {
          to: "/legal",
          label: "Legal Notice",
          isInternal: true,
        },
        {
          to: "/companies/privacy",
          label: "Data Protection Agreement",
          isInternal: true,
        },
        {
          to: "/privacy",
          label: "Privacy Policy",
          isInternal: true,
        },
        {
          to: `/recruiters/terms`,
          label: "Partner Terms",
          isInternal: true,
        },
      ],

      supportLinks: [
        {
          to:
            "https://help.moberries.com/en/collections/2140789-for-recruiters",
          label: "FAQ",
          isInternal: false,
        },
        {
          to: "https://www.moberries.com/page/affiliate-app-guide/",
          label: "Guide",
          isInternal: false,
        },
        {
          to: `mailto:support@moberries.com`,
          label: "Support",
          isInternal: false,
        },
        {
          to: `/sitemap`,
          label: "Sitemap",
          isInternal: true,
        },
      ],
    },
  },
  "de-de": {
    menuItems: [
      {
        to: "https://www.moberries.com/jobs",
        label: "Jobs",
        isInternal: false,
      },
      { to: "/de/page/uber-moberries", label: "Über uns", isInternal: true },
      {
        to: `/de/blog`,
        label: "Blog",
        isInternal: true,
      },
      {
        to: `https://www.moberries.com/jobs/company/moberries`,
        label: "Karriere",
        isInternal: false,
      },
      // MOB-974
      // {
      //   to: "/de/page/partner-api-doc",
      //   label: "API-Dokumentation für Partner",
      //   isInternal: true,
      // },
    ],
    candidates: {
      legalLinks: [
        { to: "/de/page/presse", label: "Presse", isInternal: true },
        {
          to: "/de/legal",
          label: "Impressum",
          isInternal: true,
        },
        { to: "/de/privacy", label: "Datenschutzerklärung", isInternal: true },
        {
          to: `/de/terms`,
          label: "Geschäftsbedingungen",
          isInternal: true,
        },
      ],

      supportLinks: [
        {
          to:
            "https://help.moberries.com/de/collections/2140782-fur-kandidaten",
          label: "FAQ",
          isInternal: false,
        },
        {
          to: "https://www.moberries.com/de/page/candidateguide",
          label: "Guide",
          isInternal: false,
        },
        {
          to: `mailto:support@moberries.com`,
          label: "Support",
          isInternal: false,
        },
        {
          to: `/de/sitemap`,
          label: "Sitemap",
          isInternal: true,
        },
      ],
    },

    companies: {
      menuItems: [
        { to: "/de/", label: "Start", isInternal: true },
        {
          to: "https://www.moberries.com/jobs",
          label: "Jobs",
          isInternal: false,
        },
        { to: "/de/page/uber-moberries", label: "Über uns", isInternal: true },
        {
          to: `/de/blog`,
          label: "Blog",
          isInternal: true,
        },
        {
          to: `https://www.moberries.com/jobs/company/moberries`,
          label: "Karriere",
          isInternal: false,
        },
      ],
      legalLinks: [
        { to: "/de/page/presse", label: "Presse", isInternal: true },
        {
          to: "/de/legal",
          label: "Impressum",
          isInternal: true,
        },
        {
          to: "/de/companies/privacy",
          label: "Datenschutzerklärung",
          isInternal: true,
        },
        {
          to: "/de/privacy",
          label: "Datenschutzbestimmungen",
          isInternal: true,
        },
        {
          to: `/de/companies/terms`,
          label: "Geschäftsbedingungen",
          isInternal: true,
        },
      ],

      supportLinks: [
        {
          to:
            "https://help.moberries.com/de/collections/2099568-fur-unternehmen",
          label: "FAQ",
          isInternal: false,
        },
        {
          to: "https://www.moberries.com/de/page/unternehmens-anleitung/",
          label: "Anleitung",
          isInternal: false,
        },
        {
          to: `mailto:support@moberries.com`,
          label: "Support",
          isInternal: false,
        },
        {
          to: `/de/sitemap`,
          label: "Sitemap",
          isInternal: true,
        },
      ],
    },

    default: {
      menuItems: [
        { to: "/de/", label: "Start", isInternal: true },
        {
          to: "https://www.moberries.com/jobs",
          label: "Jobs",
          isInternal: false,
        },
        { to: "/de/page/uber-moberries", label: "Über uns", isInternal: true },
        {
          to: `/de/blog`,
          label: "Blog",
          isInternal: true,
        },
        {
          to: `https://www.moberries.com/jobs/company/moberries`,
          label: "Karriere",
          isInternal: false,
        },
      ],
      legalLinks: [
        { to: "/de/page/presse", label: "Presse", isInternal: true },
        {
          to: "/de/legal",
          label: "Impressum",
          isInternal: true,
        },
        {
          to: "/de/companies/privacy",
          label: "Datenschutzerklärung",
          isInternal: true,
        },
        {
          to: `/de/companies/terms`,
          label: "Geschäftsbedingungen",
          isInternal: true,
        },
      ],

      supportLinks: [
        {
          to:
            "https://help.moberries.com/de/collections/2099568-fur-unternehmen",
          label: "FAQ",
          isInternal: false,
        },
        {
          to: "https://www.moberries.com/de/page/unternehmens-anleitung",
          label: "Guide",
          isInternal: false,
        },
        {
          to: `mailto:support@moberries.com`,
          label: "Support",
          isInternal: false,
        },
        {
          to: `/de/sitemap`,
          label: "Sitemap",
          isInternal: true,
        },
      ],
    },

    recruiters: {
      legalLinks: [
        { to: "/de/page/presse", label: "Presse", isInternal: true },
        {
          to: "/de/legal",
          label: "Impressum",
          isInternal: true,
        },
        {
          to: "/de/companies/privacy",
          label: "Datenschutzerklärung",
          isInternal: true,
        },
        {
          to: "/de/privacy",
          label: "Datenschutzbestimmungen",
          isInternal: true,
        },
        {
          to: "/de/recruiters/terms",
          label: "Geschäftsbedingungen",
          isInternal: true,
        },
      ],

      supportLinks: [
        {
          to: "https://help.moberries.com/de/collections/2140789-fur-recruiter",
          label: "FAQ",
          isInternal: false,
        },
        {
          to: "https://www.moberries.com/de/page/affiliate-app-guide/",
          label: "Guide",
          isInternal: false,
        },
        {
          to: `mailto:support@moberries.com`,
          label: "Support",
          isInternal: false,
        },
        {
          to: "/de/sitemap",
          label: "Sitemap",
          isInternal: true,
        },
      ],
    },
  },
}
export const socialLinks = [
  {
    to: "https://www.facebook.com/moberries/",
    label: "facebook",
    isInternal: false,
  },
  {
    to: "https://twitter.com/MoBerries",
    label: "twitter",
    isInternal: false,
  },
  {
    to: "https://www.linkedin.com/company/10009040",
    label: "linkedin",
    isInternal: false,
  },
  {
    to: "https://www.youtube.com/channel/UC22gC5eRgLqqnVg9zjSI53w",
    label: "youtube",
    isInternal: false,
  },
  {
    to: "https://www.instagram.com/teammoberries/",
    label: "instagram",
    isInternal: false,
  },
]

export const blogLinks = {
  "en-us": [
    { label: "Blog Home", url: "/blog" },
    {
      label: "News from the Network",
      url: "/blog/category/news-from-the-network",
    },
    { label: "Case Studies", url: "/blog/category/case-studies" },
    { label: "Videos", url: "/blog/category/video" },
    { label: "Candidate Stories", url: "/blog/category/candidate-stories" },
  ],
  "de-de": [
    { label: "Hauptseite", url: "/blog" },
    {
      label: "News aus dem Netzwerk",
      url: "/blog/category/news-from-the-network",
    },
    { label: "Case Studies", url: "/blog/category/case-studies" },
    { label: "Videos", url: "/blog/category/video" },
    {
      label: "Erfolgsstories",
      url: "/blog/category/candidate-stories",
    },
  ],
}
